/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import Utility from '../../../../Utility';
import Settings from '../../../../Settings';
import MsgInput from '../../../Error/MsgInput'
import {
  Form, Header, Button, Loader, Dimmer, Icon, Dropdown, Input, Modal, Grid
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import { connect } from 'react-redux'
var dateFormat = require('dateformat');

class BillModal extends Component {

  constructor(props) {
    super(props);
    this.modal_status = false;
    this.state = {
      stocks: [],
      stock_id: null,
      stock: null,
      status_stock: 'N',
      button_update_stock_disabled: true,
      button_update_stock_loading: false,
      invoice_title: 'เพิ่มรายการนำออกทอง 99.99',
      loader: false,
      button_save_title: 'สร้าง',
      description: '',
      msg_error: {},
      focus: 'add',
      export_date: moment(),
      weight: 0,
      weight_remain: null,
      status_bill: false,
      cost: '',
    }

    this.loadGoldPrice = this.loadGoldPrice.bind(this)
  }

  handelSubmitSaveInvoice() {
    if (this.state.stock === null) {
      alert('กรุณาเลือกบิลนำเข้า');
      return;
    }

    this.setState({ button_save_loading: true })
    if (this.state.focus === 'add') {
      var formData = {
        stock: this.state.stock_id,
        export_date: Utility.formatDate2(this.state.export_date),
        status_bill: this.state.status_bill,
        description: this.state.description,
        weight: this.state.weight ? this.state.weight : 0,
        cost: this.state.cost ? this.state.cost : 0,
        gold_price: this.state.gold_price.gold_99_buy,
      };

      const url = Settings.baseUrl + '/gold_99/export/';
      Utility.post(url, formData, (status, data) => {
        if (status) {
          this.setState({
            button_save_loading: false,
            button_save_title: 'บันทึก',
            msg_error: {},
          })
          this.setState({ focus: 'edit' })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else {
          if ('error' in data) {
            alert(data['error'])
          }
          this.setState({
            msg_error: data,
            button_save_loading: false,
          })
        }
      });
    } else {
      var formData = {
        status_bill: this.state.status_bill,
        description: this.state.description,
        weight: this.state.weight,
        cost: this.state.cost,
      };
      const url = Settings.baseUrl + '/gold_99/export/' + this.props.invoice_detail.id + "/";
      Utility.patch(url, formData, (status, data) => {
        if (status) {
          this.setState({
            button_save_loading: false,
            msg_error: {},
          })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
          alert('บันทึกข้อมูลสำเร็จ!')
        } else {
          if ('error' in data) {
            alert(data['error'])
          }
          this.setState({
            msg_error: data,
            button_save_loading: false,
          })
        }
      });
    }
  }

  loadGoldPrice() {
    let d = new Date();
    let q = dateFormat(d, "yyyy-mm-dd HH:MM")
    let url = Settings.baseUrl + "/gold_price/?datetime=" + q;
    this.setState({ is_loading: true })
    let gold_price_period = []
    Utility.get(url, (s, res) => {
      let text = ''

      for (let i in res) {
        text = Utility.priceFormat(res[i].gold_99_buy) + ' (' + 'ปัจจุบัน ' + Utility.formatTimeSecond(res[i].record_date) + ' น.' + ') '
        gold_price_period.push({ key: res[i].id, value: res[i].id, text: text })
      }

      if (s && res.length > 0) {
        this.setState({
          gold_price: res[0],
          gold_price_period: gold_price_period,
          gold_price_value: gold_price_period.length != 0 ? gold_price_period[0].value : 0,
          is_loading: false,
        })
      }
    });
  }

  loadInvoiceItem() {
    var invoices = Utility.getFetch(Settings.baseUrl + '/gold_99/export/' + this.props.invoice_detail.id + "/");
    this.setState({ loader: true });
    Promise.all([invoices]).then((values) => {
      this.setState({
        number: values[0].number,
        weight_remain: values[0].weight_remain ? values[0].weight_remain : values[0].stock.weight_remain,
        description: values[0].description,
        status_bill: values[0].status_bill === 'Y',
        export_date: moment(values[0].export_date),
        weight: values[0].weight,
        cost: values[0].cost,
        stock_id: values[0].stock.id,
        stock: values[0].stock,
        button_save_title: 'บันทึก',
        status_stock: values[0].status_stock,
        is_return: values[0].is_return,
        button_update_stock_disabled: values[0].status_stock == 'Y',
        invoice_title: <span>{<span>แก้ไขรายการนำออกทอง 99.99</span>} </span>,
        loader: false,
      });
    });
  }

  componentDidMount() {
    var self = this;
    this.setState({
      loader_active: true,
      btn_stock: true
    });
    this.setState({ focus: this.props.modal_action })
    var stock_import = Utility.getFetch(Settings.baseUrl + '/gold_99/stock/');
    Promise.all([stock_import]).then((values) => {
      this.stock_import = values[0];
      let stocks = [];
      for (let i in values[0]) {
        stocks.push({
          key: values[0][i].stock.id,
          value: values[0][i].stock.id,
          text: values[0][i].stock.number,
          obj: values[0][i].stock,
        });
      }
      this.setState({
        stocks: stocks,
      });
    });
    if (this.props.modal_action === 'edit') {
      this.loadInvoiceItem()
    }
    this.loadGoldPrice()
  }

  submitUpdateStock() {
    if (window.confirm('ยืนยันอนุมัติและอัพเดทสต็อก')) {
      this.setState({
        button_update_stock_loading: true,
      })
      var formData = {
        status_bill: this.state.status_bill,
        description: this.state.description,
        weight: this.state.weight ? this.state.weight : 0,
        cost: this.state.cost ? this.state.cost : 0,
      };
      const url = Settings.baseUrl + '/gold_99/export/' + this.props.invoice_detail.id + "/update/";
      Utility.post(url, formData, (status, data) => {
        if (status) {
          this.setState({
            button_update_stock_loading: false,
            msg_error: {},
          })
          this.props.onAddInvoice(data);
          this.loadInvoiceItem()
          alert('อนุมัติและอัพเดทสต๊อกสำเร็จ!')
        } else {
          if ('error' in data) {
            alert(data['error'])
          }
          this.setState({
            msg_error: data,
            button_update_stock_loading: false,
          })
        }
      });
    }
  }

  submitCreateReturn() {
    if (window.confirm('ยืนยันสร้างใบคืนเงิน')) {
      this.setState({
        button_update_stock_loading: true,
      })
      const url = Settings.baseUrl + '/gold_99/export/' + this.props.invoice_detail.id + "/return/";
      Utility.post(url, {}, (status, data) => {
        if (status) {
          this.setState({
            button_update_stock_loading: false,
            msg_error: {},
          })
          this.loadInvoiceItem()
          alert('สร้างใบคืนเงินสำเร็จ!')
        } else {
          if ('error' in data) {
            alert(data['error'])
          }
          this.setState({
            msg_error: data,
            button_update_stock_loading: false,
          })
        }
      });
    }
  }

  render() {
    return (
      <Modal size="large" open={true} /*dimmer='blurring'*/>
        <Button id='btnClose' circular icon='close' basic floated='right' name='' onClick={this.props.onClose} />
        <Modal.Header>
          <Header as='h4'>
            <Header.Content>
              {this.state.invoice_title} {this.state.number && <span id='textBillID'>[บิลเลขที่ {this.state.number}]</span>}
              <Header.Subheader>
                <span><Icon name={this.state.status_stock == 'N' ? 'warning circle' : 'check circle'} color={this.state.status_stock == 'N' ? 'yellow' : 'green'} />{Settings.status_stock[this.state.status_stock]}</span>
              </Header.Subheader>
            </Header.Content>
          </Header>
        </Modal.Header>
        <Modal.Content>
          <div >
            <div className='relative'>
              {this.state.loader && <Dimmer active={this.state.loader} inverted>
                <Loader inverted content='Loading' />
              </Dimmer>}
              <div>
                <Grid columns={3}>
                  <Grid.Row>
                    <Grid.Column width={16} >
                      <Form className=' fluid' size='small' >
                        <Form.Group>
                          {
                            this.state.focus === 'edit' ? <Form.Field width={16} autoComplete="off">
                              <label>*เลขที่บิลนำเข้า</label>
                              <Input
                                readOnly
                                disabled
                                value={this.state.stock ? this.state.stock.number : ''}
                              />
                            </Form.Field>
                              : <Form.Dropdown width={16}
                                label='*เลขที่บิลนำเข้า'
                                search
                                selection
                                options={this.state.stocks}
                                value={this.state.stock_id}
                                placeholder='เลขที่บิลนำเข้า'
                                onChange={(e, data) => {
                                  let s = this.state.stocks.find((s) => s.value === data.value)
                                  this.setState({
                                    stock_id: s ? s.value : null,
                                    stock: s ? s.obj : null,
                                    weight_remain: s ? s.obj.weight_remain : null,
                                    // weight: s ? s.obj.weight_remain : null,
                                  });
                                }} />
                          }
                          <Form.Field width={16}>
                            <label>วันที่</label>
                            <DatePicker
                              id='date'
                              dateFormat="DD/MM/YYYY"
                              selected={this.state.export_date}
                              readOnly
                              onChange={(date) => {
                                date = date ? date : moment()
                                this.setState({ export_date: date });
                              }}
                              disabled
                            />
                          </Form.Field  >
                          <Form.Field width={16} autoComplete="off">
                            <label>น้ำหนักคงเหลือ</label>
                            <Input
                              disabled
                              autoComplete="off"
                              className='text-right'
                              value={this.state.weight_remain ? Utility.weightFormat(this.state.weight_remain) : ''}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Form className=' fluid' size='small' >
                        <Form.Group>
                          <Form.Field width={16} autoComplete="off">
                            <label>ชื่อโรงงาน/ร้านส่ง</label>
                            <Input
                              readOnly
                              disabled
                              value={this.state.stock ? this.state.stock.vendor : ''}
                            />
                          </Form.Field>
                          <Form.Field width={16} autoComplete="off">
                            <label>ต้นทุนทองต่อบาท</label>
                            <Input
                              readOnly
                              disabled
                              className='text-right'
                              value={this.state.stock ? Utility.priceFormat(this.state.stock.cost) : ''}
                            />
                          </Form.Field>
                          <Form.Field width={16} autoComplete="off" error={this.state.msg_error.weight}>
                            <label>*น้ำหนักนำออก <MsgInput text={this.state.msg_error.weight} /></label>
                            <Input
                              autoComplete="off"
                              placeholder='0.000'
                              className='text-right'
                              disabled={this.state.status_stock === 'Y' || !this.state.weight_remain}
                              onChange={(e) => {
                                if (isNaN(Number(e.target.value))) {
                                } else {
                                  if (e.target.value.split('.')[0].length > 6) {
                                    return;
                                  }
                                  if (e.target.value.toString().split(".")[1] != undefined)
                                    if (e.target.value.toString().split(".")[1].length > 3)
                                      return
                                  if (Number(e.target.value) > Number(this.state.weight_remain)) {
                                    e.target.value = this.state.weight_remain
                                  }
                                  this.setState({ weight: e.target.value.replace(/^0+/, '') })
                                }
                              }}
                              onBlur={() => {
                                setTimeout(() => {
                                  if (this.state.focus_weight) {
                                    this.setState({
                                      focus_weight: false,
                                    });
                                  }
                                }, 0);
                              }}
                              onFocus={(e) => {
                                e.target.select()
                                if (!this.state.focus_weight) {
                                  this.setState({
                                    focus_weight: true,
                                  });
                                }
                              }}
                              value={this.state.focus_weight ? this.state.weight : Utility.weightFormat(this.state.weight)}
                              maxLength={10}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Form className=' fluid' size='small' >
                        <Form.Group>
                          <Form.Field width={16} autoComplete="off">
                            <label>มูลค่าต้นทุน</label>
                            <Input
                              readOnly
                              disabled
                              className='text-right'
                              value={this.state.stock ? Utility.priceFormat(Number(this.state.stock.cost) * 0.0656 * Number(this.state.weight || 0)) : ''}
                            />
                          </Form.Field>
                          <Form.Field width={16} autoComplete="off" error={this.state.msg_error.cost}>
                            <label>*ราคาขายต่อบาท <MsgInput text={this.state.msg_error.cost} /></label>
                            <Input
                              autoComplete="off"
                              placeholder='0.00'
                              className='text-right'
                              disabled={this.state.status_stock === 'Y' || !this.state.weight_remain}
                              onChange={(e) => {
                                if (isNaN(Number(e.target.value))) {
                                } else {
                                  if (e.target.value.split('.')[0].length > 6) {
                                    return;
                                  }
                                  if (e.target.value.toString().split(".")[1] != undefined)
                                    if (e.target.value.toString().split(".")[1].length > 2)
                                      return
                                  this.setState({ cost: e.target.value.replace(/^0+/, '') })
                                }
                              }}
                              onBlur={() => {
                                setTimeout(() => {
                                  if (this.state.focus_cost) {
                                    this.setState({
                                      focus_cost: false,
                                    });
                                  }
                                }, 0);
                              }}
                              onFocus={(e) => {
                                e.target.select()
                                if (!this.state.focus_cost) {
                                  this.setState({
                                    focus_cost: true,
                                  });
                                }
                              }}
                              value={this.state.focus_cost ? this.state.cost : Utility.priceFormat(this.state.cost)}
                              maxLength={10}
                            />
                          </Form.Field>
                          <Form.Field width={16} autoComplete="off">
                            <label>มูลค่าขายออก</label>
                            <Input
                              readOnly
                              disabled
                              className='text-right'
                              value={Utility.priceFormat(Number(this.state.cost || 0) * 0.0656 * Number(this.state.weight || 0))}
                            />
                          </Form.Field>
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Form className=' fluid' size='small' >
                        <Form.Group>
                          <Form.Checkbox
                            width={3}
                            label='เคลียร์บิล'
                            checked={this.state.status_bill}
                            onChange={(e, v) => {
                              this.setState({
                                status_bill: !this.state.status_bill
                              });
                            }} />
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column width={16}>
                      <Form className=' fluid' size='small' >
                        <Form.Group>
                          <Form.Field width={16} autoComplete="off">
                            <label>หมายเหตุ</label>
                            <Input
                              autoComplete="off"
                              placeholder='หมายเหตุ'
                              className=''
                              onChange={(e) => {
                                this.setState({ description: e.target.value })
                              }}
                              value={this.state.description}
                              maxLength={150}
                            />
                          </Form.Field>
                          <Form.Field width={16} autoComplete="off"></Form.Field>
                          <Form.Field width={16} autoComplete="off"></Form.Field>
                        </Form.Group>
                      </Form>
                    </Grid.Column>
                    <Grid.Column>
                      <Form>
                        <Form.Dropdown
                          label='ราคาทอง 99.99 (บาท)' fluid selection width={16}
                          options={this.state.gold_price_period}
                          value={this.state.gold_price_value}
                          // onChange={this.goldPriceChangeHandler} 
                          disabled={true}
                          style={{ 'word-break': 'break-word' }}
                          openOnFocus={false}
                        />
                      </Form>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </div>
            </div>
          </div>

        </Modal.Content>
        <Modal.Actions>
          {
            (!this.state.is_return && this.state.status_stock === 'Y') ? <Button
              id='btnUpdate'
              size='small'
              icon='money'
              color='green'
              onClick={(e) => {
                this.submitCreateReturn()
              }
              }
              className={this.state.button_update_stock_loading ? 'loading' : ''}
              disabled={this.state.button_update_stock_loading}
              content='สร้างใบคืน' />
              : ''
          }
          <Button
            id='save'
            size='small'
            primary
            icon='save'
            labelPosition='left'
            onClick={(e) => {
              e.preventDefault();
              this.handelSubmitSaveInvoice();
            }}
            className={this.state.button_save_loading ? 'loading' : ''}
            disabled={this.state.button_save_loading}
            content={this.state.button_save_title} />
          {
            (this.state.focus === 'edit' && this.state.status_stock === 'N') ? <Button
              id='btnUpdate'
              size='small'
              icon='lightning'
              color='red'
              onClick={(e) => {
                this.submitUpdateStock()
              }
              }
              className={this.state.button_update_stock_loading ? 'loading' : ''}
              disabled={this.state.button_update_stock_disabled}
              content='อนุมัติและอัพเดทสต๊อก' />
              : ''
          }
        </Modal.Actions>
      </Modal>
    )
  }

}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(BillModal)