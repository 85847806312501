/*eslint eqeqeq: "off"*/
/*eslint jsx-a11y/anchor-is-valid: "off"*/
/*eslint no-unused-vars: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint array-callback-return: "off"*/
/*eslint no-redeclare: "off"*/
/*eslint default-case: "off"*/
/*eslint no-whitespace-before-property: "off"*/
/*eslint react/no-direct-mutation-state: "off"*/
/*eslint no-useless-constructor: "off"*/
/*eslint react/jsx-no-duplicate-props: "off"*/

import React, { Component } from 'react';
import {
  Form, Header, Button, Loader, Dimmer, Icon, Pagination, Input, Dropdown, Modal
} from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import BillTable from './BillTable'
import Settings from '../../../Settings';
import Utility from '../../../Utility';
import BillModal from './BillModal';
import { connect } from 'react-redux'

class ReturnCashPawned extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message_error_hidden: true,
      items: [],
      edit: null,
      table_heigh: Settings.table_heigh,
      page: 1,
      totalPages: 1,
      colSortDirs: {
        'number': 'DESC',
      },
      number: '',
      is_add: false,
      add: 0,
      btn_load: false,
    }
    this.columnKey = 'number'
    this.sortDi = 'DESC'
    this.onSortChange = this.onSortChange.bind(this)
    this.onClickitem = this.onClickitem.bind(this);
    this.loaddata = this.loaddata.bind(this);
    this.create = this.create.bind(this);
  }

  onSortChange(columnKey, sortDir) {

    this.columnKey = columnKey
    this.sortDi = sortDir
    this.loaddata(1)
    this.setState({
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  componentDidMount() {
    this.loaddata(1)
  }

  handlePaginationChange(e, { activePage }) {
    this.setState({ page: activePage }, () => {
      this.loaddata(activePage)
    })
  }

  loaddata(page) {
    this.setState({
      loader_active: true,
    });

    var invoices = Utility.getFetch(`${Settings.baseUrl}/return-cash-pawned/?number=${this.state.number || ''}&columnKey=${this.columnKey}&sortDi=${this.sortDi}&page=${page}`);
    Promise.all([invoices]).then((values) => {
      this.setState({
        items: this.setFieldValue(values[0].results),
        loader_active: false,
        totalPages: values[0].next == null && values[0].previous == null ? 0 : Math.ceil(values[0].count / 10),
        counter: values[0].count
      });
      let elHeight = document.getElementById('table_width')
      if (elHeight)
        this.setState({ table_width: elHeight.clientWidth, table_heigh: window.innerHeight - 400 });
    });
  }

  setFieldValue(items) {
    for (let i = 0; i < items.length; i++) {
      items[i]['total'] = Utility.numberFormat(Number(items[i]['total']))
      items[i]['record_datetime'] = Utility.formatDate(items[i]['record_datetime'])
      items[i]['stock_datetime'] = items[i]['stock_datetime'] ? Utility.formatDate(items[i]['stock_datetime']) : ''
    }
    return items;
  }

  clearFormSearch() {
    this.setState({
      number: '',
    })
  }

  onClickitem(e, d) {

    let data = this.state.items[d.positon];

    e.preventDefault();
    this.setState({
      edit: data.id,
    });
  }

  create() {
    this.setState({ btn_load: true })
    Utility.post(`${Settings.baseUrl}/return-cash-pawned/`, { total: this.state.add }, (status, data) => {
      if (status) {
        this.setState({
          btn_load: false,
          edit: data.id,
          is_add: false,
          add: 0,
          page: 1,
          number: '',
        }, () => this.loaddata(1))
        alert('บันทึกข้อมูลสำเร็จ!')
      } else {
        if ('error' in data) {
          alert(data['error'])
        }
        this.setState({
          btn_load: false,
        })
      }
    });
  }

  render() {
    let items = this.state.items;
    return (

      <div className='invoice-box'>

        <Form size='small'>
          <Form.Group>
            <Form.Field width={8}>
              <Header floated='left' as='h2'>คืนเงินทองหลุดจำนำ</Header>
            </Form.Field>
            <Form.Field width={16}>
              {/* <Button id='addExportMelt' size='small' content='สร้างใบคืนเงิน' onClick={() => {
                this.setState({ is_add: true, add: 0 });
              }} floated='right' icon='plus' labelPosition='right' type='button' primary /> */}
            </Form.Field>
          </Form.Group>
        </Form>

        <Form className='attached fluid' size='small'>
          <Form.Group>
            <Form.Field width={6}>
              <label>เลขที่บิล</label>
              <Input
                placeholder='เลขที่บิล'
                onChange={(e) => { this.setState({ number: e.target.value }) }}
                value={this.state.number}
              />
            </Form.Field>
            <Form.Field width={10} style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
              <Button id='clear_search' floated='right' type='button' size='small' onClick={() => {
                this.clearFormSearch()
              }} > รีเซ็ต</Button>
              <Button id='search' floated='right' type='button' size='small' onClick={() => {
                this.setState({ page: 1 }, () => {
                  this.loaddata(1)
                })
              }} ><Icon name='search' /> ค้นหา</Button>
            </Form.Field>
          </Form.Group>
        </Form>
        {this.state.loader_active && <Dimmer active={this.state.loader_active} inverted>
          <Loader inverted content='Loading' />
        </Dimmer>}
        <div id="table_width">
          <BillTable items={items} table_width={this.state.table_width} onActionItemCell={this.onClickitem} onSortChange={this.onSortChange} colSortDirs={this.state.colSortDirs} />
          <br />
          <Form size='small'>
            <Form.Group>
              <Form.Input id='amounttrans' label='จำนวน' placeholder='' className='text-right' width={2} value={Utility.numberFormat(this.state.counter)} />
              <br />
              <Form.Field width={14}>
                <br />
                {this.state.totalPages > 1 && <Pagination
                  floated='right'
                  size='tiny'
                  activePage={this.state.page}
                  onPageChange={this.handlePaginationChange.bind(this)}
                  totalPages={this.state.totalPages}
                />}
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
        <br />
        <Form size='small' >
          <Form.Group>
            <Form.Field width={16}>
              <br />
              {this.state.edit ? <BillModal
                open={true}
                edit={this.state.edit}
                onClose={() => {
                  this.setState({ edit: null });
                  this.loaddata(this.state.page)
                }}
              /> : ''}
            </Form.Field>
          </Form.Group>
        </Form>
        {
          this.state.is_add && <Modal size="mini" open={true} /*dimmer='blurring'*/>
            <Button id='btnClose' circular icon='close' basic floated='right' name='' disabled={this.state.btn_load} onClick={() => { this.setState({ is_add: false, add: 0 }) }} />
            <Modal.Header>
              <Header as='h4'>
                <Header.Content>
                  สร้างใบคืนเงิน
                </Header.Content>
              </Header>
            </Modal.Header>
            <Modal.Content>
              <Form className=' fluid' size='small' >
                <Form.Field width={16} autoComplete="off">
                  <label>ยอดเงินทั้งหมด</label>
                  <Input
                    autoComplete="off"
                    placeholder='0'
                    className='text-right'
                    onChange={(e) => {
                      if (isNaN(Number(e.target.value))) {
                      } else {
                        if (e.target.value.split('.')[0].length > 10) {
                          return;
                        }
                        if (e.target.value.toString().split(".")[1] != undefined)
                          if (e.target.value.toString().split(".")[1].length > 0)
                            return
                        this.setState({ add: e.target.value.replace(/^0+/, '') })
                      }
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        if (this.state.focus) {
                          this.setState({
                            focus: false,
                          });
                        }
                      }, 0);
                    }}
                    onFocus={(e) => {
                      e.target.select()
                      if (!this.state.focus) {
                        this.setState({
                          focus: true,
                        });
                      }
                    }}
                    value={this.state.focus ? this.state.add : Utility.numberFormat(this.state.add)}
                    maxLength={10}
                  />
                </Form.Field>
              </Form>
            </Modal.Content>
            <Modal.Actions>
              <Button
                size='small'
                primary
                icon='add'
                labelPosition='left'
                onClick={(e) => {
                  e.preventDefault();
                  if (this.state.add > 0) {
                    if (window.confirm(`ต้องการสร้างใบคืนเงิน ยอดเงินรวม ${Utility.priceFormat(this.state.add)} หรือไม่`)) {
                      this.create();
                    }
                  } else {
                    alert('ยอดเงินรวมต้องมากกว่า 0')
                  }
                }}
                className={this.state.btn_load ? 'loading' : ''}
                disabled={this.state.add <= 0 || this.state.btn_load}
                content='สร้าง' />
            </Modal.Actions>
          </Modal>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return ({
    auth: state.auth,
  })
}
export default connect(
  mapStateToProps,
)(ReturnCashPawned)